import React, { Component, Suspense, lazy } from 'react';
import { AppContext } from './context';
import ErrorBoundary from './components/ErrorBoundary';
import Loading from './components/Loading';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { isMobile, APP_VERSION } from './utils';
import './App.css';
import { message, Card } from 'antd';
import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";

const AsyncSignInPage = lazy(() => import('./SignInPage'));
const AsyncAppIndex = lazy(() => import('./app/index'));
const AsyncAdminIndex = lazy(() => import('./admin/index'));

firebase.initializeApp({
  "apiKey": "AIzaSyAFDyGNPz1uMoqC1-Cb6fgognxnshi3tao",
  "authDomain": "easybus-laundry.firebaseapp.com",
  "databaseURL": "https://easybus-laundry.firebaseio.com",
  "projectId": "easybus-laundry",
});

class App extends Component {
  state = {}
  componentDidMount() {
    this._mounted = true;

    this.onListener = firebase.auth().onAuthStateChanged((account) => {
        if (!this._mounted) return;

        if (account) {
          account.getIdTokenResult().then(({claims}) => {
            if (!claims||!claims.businessId) {
              firebase.auth().signOut();
            } else {
              this.setState({account: account||null, claims});
            }
          });
        } else {
          this.setState({account: null, claims: null});
        }
      });

    const currentVersion = localStorage.getItem('appVersion');
    if (currentVersion!==APP_VERSION) {
      localStorage.setItem('appVersion', APP_VERSION);
      message.success(`New version ${APP_VERSION} updated`, 'success');
    }
  }
  componentWillUnmount() {
    this._mounted = false;
    this.onListener&&this.onListener();
    this.onListener = null;
  }
  render() {
    const { account, claims } = this.state;
    const mobile = isMobile();

    return (
      <AppContext.Provider value={{
        firebase,
        account,
        businessId: claims&&claims.businessId,
        fontSize: (claims&&claims.fontSize)||'large-font'
      }}>
        <ErrorBoundary>
          <BrowserRouter>
            <Suspense fallback={<div className='padding'><Card loading/></div>}>
              <Switch>
                {!account&&<Route exact path="/loading" component={Loading}/>}
                {!account&&<Route exact path="/login" render={props=><AsyncSignInPage {...props} loading={account===undefined}/>}/>}
                {account&&<Route path={mobile?`/app`:`/admin`} component={mobile?AsyncAppIndex:AsyncAdminIndex}/>}
                <Redirect push={false} to={account?(mobile?`/app`:`/admin`):'/loading'}/>
              </Switch>
            </Suspense>
          </BrowserRouter>
        </ErrorBoundary>
      </AppContext.Provider>
    );
  }
}

export default App;
