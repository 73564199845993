export const APP_VERSION = '1.0.0';

export function isMobile() {
  if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    return true;
  }
};

export function formatPrice(price) {
  return Math.round(price * 100) / 100;
}

export const LINEN_PROCESS_PICKUP = 'Pickup';
export const LINEN_PROCESS_COUNT = 'Count';
export const LINEN_PROCESS_DELIVER = 'Deliver';
export const HOTEL_DEPARTMENT_GUEST_LAUNDRY = 'Guest Laundry';

export const ITEM_TYPE_REWASH = 'rewash';
export const ITEM_TYPE_SPECIAL = 'special';
export const ITEM_TYPE_NEW = 'newitem';

export function getOperator(account) {
  return account&&{
    id: account.uid,
    displayName: account.displayName,
    date: new Date()
  };
}

export function toFormattedDate(date, start, end) {
  return {
    unix: date.toDate(),
    display: date.format('HH:mm MMM Do YYYY'),
    short: date.format('HH:mm DD/MM'),
    year: date.format('YYYY'),
    month: date.format('YYYY-MM'),
    date: date.format('YYYY-MM-DD'),
    ...((typeof start === 'number')&&{
      start
    }),
    ...((typeof end === 'number')&&{
      end
    })
  };
}

function linenItemToDisplayArray(product, pickup, deliver) {
  return [
    ...(((typeof (pickup&&pickup.qty) === 'number')||(typeof (deliver&&deliver.qty) === 'number'))?[
      {name: `${product.name}${product.nameCn?` (${product.nameCn})`:''}`, ...(pickup&&(typeof pickup.qty === 'number')&&{pickup: pickup.qty}), ...(deliver&&(typeof deliver.qty === 'number')&&{deliver: deliver.qty}) }
    ]:[]),
    ...(((typeof (pickup&&pickup.rewash) === 'number')||(typeof (deliver&&deliver.rewash) === 'number'))?[
      {name: `${product.name}${product.nameCn?` (${product.nameCn})`:''}`, type: 'REWASH', ...(pickup&&(typeof pickup.rewash === 'number')&&{pickup: pickup.rewash}), ...(deliver&&(typeof deliver.rewash === 'number')&&{deliver: deliver.rewash}) }
    ]:[]),
    ...(((typeof (pickup&&pickup.special) === 'number')||(typeof (deliver&&deliver.special) === 'number'))?[
      {name: `${product.name}${product.nameCn?` (${product.nameCn})`:''}`, type: 'SPECIAL', ...(pickup&&(typeof pickup.special === 'number')&&{pickup: pickup.special}), ...(deliver&&(typeof deliver.special === 'number')&&{deliver: deliver.special}) }
    ]:[]),
    ...(((typeof (pickup&&pickup.newitem) === 'number')||(typeof (deliver&&deliver.newitem) === 'number'))?[
      {name: `${product.name}${product.nameCn?` (${product.nameCn})`:''}`, type: 'NEW', ...(pickup&&(typeof pickup.newitem === 'number')&&{pickup: pickup.newitem}), ...(deliver&&(typeof deliver.newitem === 'number')&&{deliver: deliver.newitem}) }
    ]:[])
  ];
}

export function toLinenDisplayItems(pickups, deliveries) {
  let dataSource = [].concat.apply([], (pickups||[]).map(pickup => {
    const deliver = deliveries&&deliveries.find(deliver=>deliver.id===pickup.id);
    return linenItemToDisplayArray(pickup, pickup, deliver);
  }));

  let missingDelivers = deliveries&&deliveries.filter(deliver=>!pickups||!pickups.find(pickup => pickup.id===deliver.id));
  if (missingDelivers&&(missingDelivers.length > 0)) {
    dataSource = [...dataSource, ...[].concat.apply([], missingDelivers.map(deliver => {
      return linenItemToDisplayArray(deliver, null, deliver);
    }))];
  }
  return dataSource;
}